import React from 'react';
import logo from '../../logo.png';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {
	Row,
	Col,
	Icon,
} from 'antd';
import {NavLink} from "react-router-dom";
import {Typography} from 'antd';
import {Layout, Menu, Breadcrumb} from 'antd';
import axios from "axios";
import Profile from "../Profile/Profile";
import Cookies from "js-cookie";
import { API_URL, CONSIGNMENTS_URL } from '../../config'

const {Content, Footer, Sider} = Layout;
const {Title} = Typography;
let user
Cookies.get("username")
if (Cookies.get("username")) {
//	user = JSON.parse(Cookies.get("username")).username
}
let today = new Date();
let dd = today.getDate();
let mm = today.getMonth() + 1; //January is 0!

let yyyy = today.getFullYear();
if (dd < 10) {
	dd = '0' + dd;
}
if (mm < 10) {
	mm = '0' + mm;
}

class History extends React.Component {
	state = {
		collapsed: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			columnDefs: [{
				headerName: "Дата", field: "timestamp"
			}, {
				headerName: "Номер товарной партии", field: "consNum"
			}, {
				headerName: "Статус", field: "state"
			}, {
				headerName: "Коментарии", field: "comments", width: 380
			}, {
				headerName: "Изменения", field: "changes", width: 420
			}],
			defaultColDef: {resizable: true}
		}
	}
	componentDidMount() {
		let expeditorId = ''
		expeditorId = Cookies.get("jwt")
		axios.post(`${API_URL}${CONSIGNMENTS_URL}`, {
			query: "gethistory",
			expeditorId: expeditorId
		})
			//.then(result => console.log(result.data.statusCode))
			.then(result => result.data)
			.then(rowData => rowData.length > 1 ? this.setState({rowData}) : this.setState({rowData}) )
	}

	render() {

		return (
			<Layout style={{minHeight: '100vh'}}>
				<Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
					<div className="logo"><img src={logo} alt="logo"/></div>
					<Menu theme="dark" defaultSelectedKeys={['3']} mode="inline">
						<Menu.Item key="1"><NavLink to="/consignment">
							<Icon type="unordered-list"/>
							<span>Товарные партии</span></NavLink>
						</Menu.Item>
						<Menu.Item key="2"><NavLink to="/upload">
							<Icon type="download"/>
							<span>Загрузка обновлений</span></NavLink>
						</Menu.Item>
						<Menu.Item key="3"><NavLink to="/history">
							<Icon type="history"/>
							<span>История обновлений</span></NavLink>
						</Menu.Item>
					</Menu>
				</Sider>
				<Layout>
					<Content style={{margin: '0 16px'}}>
						<Row type="flex" justify="space-between">

							<Breadcrumb style={{margin: '16px 0'}}>
								<Breadcrumb.Item>Портал экспедиторов</Breadcrumb.Item>
								<Breadcrumb.Item>История обновлений</Breadcrumb.Item>
							</Breadcrumb>
							<Profile username={user}/>

						</Row>

						<Row type="flex" justify="start">
							<Col xs={20} sm={20} md={20} lg={20} xl={20} style={{padding: 15}}>
								<Title level={3}>Товарные партии</Title>
							</Col>
						</Row>
						<Row type="flex" justify="start">
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<div
									className="ag-theme-material"
									style={{

										width: '100%'
									}}
								>
									<AgGridReact
										columnDefs={this.state.columnDefs}
										rowData={this.state.rowData}
										rowClassRules={{
											"queue": function(params) {
												if (params.data.stateCode === 'queue') {
													return true
												} else {
													return false
												}
											},
											"accepted": function(params) {
												if (params.data.stateCode === 'accepted') {
													return true
												} else {
													return false
												}
											},
											"rejected": function(params) {
												if (params.data.stateCode === 'download_error' || params.data.stateCode === 'processing_error') {
													return true
												} else {
													return false
												}
											}
										}}
										defaultColDef={this.state.defaultColDef}>
									</AgGridReact>
								</div>

							</Col>

						</Row>
					</Content>
					<Footer style={{textAlign: 'center'}}>Группа компаний IEK © 1999—{yyyy}. Все права
						защищены.</Footer>
				</Layout>
			</Layout>
		);
	}
}

export default History;
