import React from 'react';
import logo from '../../logo.png';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {
	Row,
	Col,
	Button,
	Icon,
} from 'antd';
import {NavLink} from "react-router-dom";
import {Typography} from 'antd';
import {Layout, Menu, Breadcrumb} from 'antd';
import axios from "axios";
import ReactExport from "react-export-excel";
import Profile from "../Profile/Profile";
import Cookies from "js-cookie";
import { API_URL, CONSIGNMENTS_URL } from '../../config'



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Content, Footer, Sider} = Layout;
const {Title} = Typography;
let user = ''
if (Cookies.get("username")) {
	//console.log(Cookies.get("jwt"))
	user = Cookies.get("jwt")
}
let today = new Date();
let dd = today.getDate();
let mm = today.getMonth() + 1; //January is 0!

let yyyy = today.getFullYear();
if (dd < 10) {
	dd = '0' + dd;
}
if (mm < 10) {
	mm = '0' + mm;
}
let date = dd + '-' + mm + '-' + yyyy;

class Consignment extends React.Component {
	state = {
		collapsed: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			columnDefs: [{
				headerName: "Номер товарной партии", field: "consNum"
			}, {
				headerName: "Отправка транспортного средства от поставщика - прогноз", field: "delivCustomEst"//sendCarEst
			}, {
				headerName: "Отправка транспортного средства от поставщика - факт", field: "delivCustomFact"//sendCarFact
			}, {
				headerName: "Доставка партии в порт - прогноз", field: "delivPortEst"
			}, {
				headerName: "Доставка партии в порт - факт", field: "delivPortFact"
			}, {
				headerName: "Отправка партии из порта на ТО - прогноз", field: "sendPartTOEst"
			}, {
				headerName: "Отправка партии из порта на ТО - факт", field: "sendPartTOFact"
			}, {
				headerName: "Доставка партии на таможенный терминал РФ - прогноз", field: "delivCustomTerminalEst"//delivCustomEst
			}, {
				headerName: "Доставка партии на таможенный терминал РФ - факт", field: "delivCustomTerminalFact"//delivCustomFact
			}, {
				headerName: "Начало ТО партии - факт", field: "startToFactFact"
			}, {
				headerName: "Окончание ТО партии - факт", field: "endToFactFact"
			}, {
				headerName: "Отправка партии по России - прогноз", field: "sendDomesticEst"
			}, {
				headerName: "Отправка партии по России - факт", field: "sendDomesticFact"
			}, {
				headerName: "Прибытие партии на склад иэк – прогноз экспертный", field: "PartyArrivalEst"//delivIEKest
			}, {
				headerName: "Номер коносамента", field: "cononsNo"
			}, {
				headerName: "Номер контейнера", field: "ContainerNumber"
			},
			],
			defaultColDef: { resizable: true }
		}
	}

	componentDidMount() {
		if (!this.state.rowData) {let expeditorId = ''
			expeditorId = Cookies.get("jwt")
			axios.post(`${API_URL}${CONSIGNMENTS_URL}`, {
				query: "getlist",
				expeditorId: expeditorId
			})
				//.then(result => console.log(result.data.statusCode))
				.then(result => result.data)
				.then(rowData => rowData.length > 1 ? this.setState({rowData}) : this.setState({rowData}) )
		}


	}

	render() {

		return (
			<Layout style={{minHeight: '100vh'}}>
				<Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
					<div className="logo"><img src={logo} alt="logo"/></div>
					<Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
						<Menu.Item key="1"><NavLink to="/consignment">
							<Icon type="unordered-list"/>
							<span>Товарные партии</span></NavLink>
						</Menu.Item>
						<Menu.Item key="2"><NavLink to="/upload">
							<Icon type="download"/>
							<span>Загрузка обновлений</span></NavLink>
						</Menu.Item>
						<Menu.Item key="3"><NavLink to="/history">
							<Icon type="history"/>
							<span>История обновлений</span></NavLink>
						</Menu.Item>
					</Menu>
				</Sider>
				<Layout>
					<Content style={{margin: '0 16px'}}>
						<Row type="flex" justify="space-between">

							<Breadcrumb style={{margin: '16px 0'}}>
								<Breadcrumb.Item>Портал экспедиторов</Breadcrumb.Item>
								<Breadcrumb.Item>Товарные партии</Breadcrumb.Item>
							</Breadcrumb>
							<Profile username={user}/>

						</Row>

						<Row type="flex" justify="start">
							<Col xs={20} sm={20} md={20} lg={20} xl={20} style={{padding: 15}}>
								<Title level={3}>Товарные партии</Title>
							</Col>
						</Row>
						<Row style={{margin: 15}}>
							<ExcelFile filename={user +'-date-'+ date}
									   element={<Button type="dashed">Скачать .xls</Button>}>
								<ExcelSheet data={this.state.rowData} name="Employees">
									<ExcelColumn label="Номер товарной партии" value="consNum"/>
									<ExcelColumn label="Отправка транспортного средства от поставщика - прогноз"
												 value="delivCustomEst"/>
									<ExcelColumn label="Отправка транспортного средства от поставщика - факт"
												 value="delivCustomFact"/>
									<ExcelColumn label="Доставка партии в порт - прогноз" value="delivPortEst"/>
									<ExcelColumn label="Доставка партии в порт - факт" value="delivPortFact"/>
									<ExcelColumn label="Отправка партии из порта на ТО - прогноз"
												 value="sendPartTOEst"/>
									<ExcelColumn label="Отправка партии из порта на ТО - факт"
												 value="sendPartTOFact"/>
									<ExcelColumn label="Доставка партии на таможенный терминал РФ - прогноз"
												 value="delivCustomTerminalEst"/>
									<ExcelColumn label="Доставка партии на таможенный терминал РФ - факт"
												 value="delivCustomTerminalFact"/>
									<ExcelColumn label="Начало ТО партии - факт" value="startToFactFact"/>
									<ExcelColumn label="Окончание ТО партии - факт" value="endToFactFact"/>
									<ExcelColumn label="Отправка партии по России - прогноз"
												 value="sendDomesticEst"/>
									<ExcelColumn label="Отправка партии по России - факт" value="sendDomesticFact"/>
									<ExcelColumn label="Прибытие партии на склад иэк – прогноз экспертный"
												 value="PartyArrivalEst"/>
									<ExcelColumn label="Номер коносамента" value="cononsNo"/>
									<ExcelColumn label="Номер контейнера" value="ContainerNumber"/>
								</ExcelSheet>
							</ExcelFile>

						</Row>
						<Row type="flex" justify="start">
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<div
									className="ag-theme-material"
									style={{

										width: '100%'
									}}
								>
									<AgGridReact
										columnDefs={this.state.columnDefs}
										rowData={this.state.rowData}
										defaultColDef={this.state.defaultColDef}>
									</AgGridReact>
								</div>

							</Col>

						</Row>
					</Content>
					<Footer style={{textAlign: 'center'}}>Группа компаний IEK © 1999—{yyyy}. Все права
						защищены.</Footer>
				</Layout>
			</Layout>
		);
	}
}

export default Consignment;
