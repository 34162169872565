import React from 'react';
import {Avatar, Icon} from "antd";
import Cookies from "js-cookie";
import {NavLink, Redirect} from "react-router-dom";

function Profile(props) {
	return (
		<div style={{margin: '16px 0px'}}><Avatar style={{margin: '0px 10px'}} icon={<Icon type="user" />} /><NavLink  to={'/'}><span onClick={() => {

			if (!process.browser) {
				return;
			}
			Cookies.remove("jwt");
			Cookies.remove("username");
			//Cookies.remove("cart");

			// to support logging out from all windows
			window.localStorage.setItem("logout", Date.now());
			//Router.push("/");
			//	rerenderEntireTree()
			return (
				<Redirect
					to={{
						pathname: '/',

					}}
				/>)
		}
		}>
				Выход
			</span></NavLink></div>
	)
}

export default Profile
