import React from 'react';
import './App.css';
import 'antd/dist/antd.css'
import {BrowserRouter, Route, Redirect} from "react-router-dom";
import Auth from './components/Auth/Auth';
import Register from './components/Register/Register';
import Consignment from './components/Consignment/Consignment';
import Upload from './components/Upload/Upload';
import Cookies from "js-cookie";
import History from "./components/History/Histoty";

//Cookies.get("jwt");

function App() {
  return (
      <BrowserRouter>
        <div>
          <Route exact path='/' component={Auth}/>
          <Route exact path='/registration/' component={Register}/>
            {/* <Route exact path='/consignment/' component={Consignment}/>
			<Route exact path='/upload/' component={Upload}/>*/}
            <Route exact path='/consignment/' render={props => (
                Cookies.get("jwt") != null ? (
                    <Consignment {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/',

                    }}
                    />
                )
            )} />
            <Route exact path='/upload/' render={props => (
                Cookies.get("jwt") != null ? (
                    <Upload {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/',

                    }}
                    />
                )
            )} />
            <Route exact path='/history/' render={props => (
                Cookies.get("jwt") != null ? (
                    <History {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/',

                    }}
                    />
                )
            )} />
        </div>
      </BrowserRouter>
  );
}

export default App;
