import React from 'react';
import {Form, Icon, Input, Button, Row, Col, notification} from 'antd';
import {Typography} from 'antd';
import axios from "axios";
import Cookies from "js-cookie";

import { API_URL, LOGIN_URL } from '../../config'


const {Title} = Typography;

function openNotificationWithIcon(type, text) {
	notification[type]({
		message: 'Ошибка',
		description:
		text,
	});
};

export const setToken = token => {

	if (!process.browser) {
		return;
	}
	Cookies.set("username", token.data.user.username);
	Cookies.set("jwt", token.data.user.expeditorId);
	//console.log(token.data)
	//setUser(token.data.user)
	if (Cookies.get("username")) {
	//	console.log(Cookies.get("jwt"))
		// Router.push("/");
	}
//	rerenderEntireTree()
};

class NormalLoginForm extends React.Component {

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
			//	console.log('Received values of form: ', values);

				axios
					.post(`${API_URL}${LOGIN_URL}`, {
						login: values.username,
						password: values.password,
					})
					.then(response => {
						// Handle success.
						//console.log(response.data.statusCode)
						if (response.data.statusCode === "400") {
							let errMsg = 'Неправильный логин или пароль.'
							openNotificationWithIcon('error', errMsg);
						}
						if (response.data.user) {
							setToken(response);
							let path = `consignment`;
							this.props.history.push(path);
						}

					})
					.catch(error => {
						//console.log(error)
						//console.log(JSON.parse(error.response.request.response).message[0].messages[0].message)

						let msg = JSON.parse(error.response.request.response).message[0].messages[0].message
						if (msg === 'Identifier or password invalid.') {
							let errMsg = 'Неправильный логин или пароль.'
							openNotificationWithIcon('error', errMsg)
						}


					});

			}
		});
	};

	render() {
		const {getFieldDecorator} = this.props.form;
		return (
			<div>
				<Row type="flex" justify="center">
					<Title style={{marginTop: 30}}>Добро пожаловать на портал экспедиторов</Title>
				</Row>
				<Row type="flex" justify="center">
					<Col xs={24} sm={20} md={12} lg={8} xl={6} style={{marginTop: 50, padding: 15}}>
						<Form onSubmit={this.handleSubmit} className="login-form">
							<Form.Item>
								{getFieldDecorator('username', {
									rules: [{required: true, message: 'Введите имя пользователя!'}],
								})(
									<Input
										prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
										placeholder="Логин"
									/>,
								)}
							</Form.Item>
							<Form.Item>
								{getFieldDecorator('password', {
									rules: [{required: true, message: 'Введите пароль!'}],
								})(
									<Input
										prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
										type="password"
										placeholder="Пароль"
									/>,
								)}
							</Form.Item>
							<Form.Item type="flex" justify="center">
								<Row type="flex" justify="center">
									<Button type="primary" htmlType="submit" className="login-form-button">
										Войти
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>

				</Row>
			</div>
		);
	}
}

const WrappedNormalLoginForm = Form.create({name: 'normal_login'})(NormalLoginForm);
export default WrappedNormalLoginForm;
